/* Mobile menu all the time */

.pine #main-menu {
  display: none !important;
}

.pine a.m-icon-link {
  padding: 11px 13px 9px;
  color: #fff;
  text-decoration: none;
}

.pine #mobile-menu {
  background: #373737;
  width: 50vw;
  position: absolute;
  right: 0;
  z-index: 300; /* go over the layers / search of map */
}

#mobile-menu li {
  list-style: none !important;
  margin: 0;
  padding: 0;
}

#mobile-menu ul li a {
  color: #fff;
  text-decoration: none;
  display: block;
  line-height: 40px;
  padding-left: 10px;
}

/* End mobile menu */

@media (max-width: 767px) {
  .pine #mobile-menu {
    width: 100vw;
  }

}

@media (min-width: 768px) {
  /* Hide the mobile search icon when larger one is present */
  #mobile-search-link {
    display: none !important;
  }

  #mobile-icon-menu {
    display: block !important;
    position: absolute;
    top: 40px;
    right: 5%;
  }
}